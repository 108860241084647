import React, { useState } from "react"
import PropTypes from "prop-types"
import CssBaseline from "@material-ui/core/CssBaseline"
import Container from "@material-ui/core/Container"
import Header from "../components/page/Header"
import { ThemeProvider } from "@material-ui/styles"
import { theme } from "../var/theme"
import NoSsr from "@material-ui/core/NoSsr"
import { Card, CardContent, CircularProgress, createStyles, makeStyles, useScrollTrigger } from "@material-ui/core"
import Zoom from "@material-ui/core/Zoom"
import Fab from "@material-ui/core/Fab"
import { KeyboardArrowUpRounded as KeyboardArrowUpIcon } from "@material-ui/icons"
import { scrollTo, validateEmail } from "../var/util"
import Footer from "../components/partials/Footer"
import Typography from "@material-ui/core/Typography"
import { Link } from "gatsby"
import Meta from "../components/Meta"
import TextField from "@material-ui/core/TextField"
import Grid from "@material-ui/core/Grid"
import Button from "@material-ui/core/Button"
import axios from "axios"
import { URLS } from "../var/const"
import SubscribeIcon from "@material-ui/icons/SendRounded"
import Message from "../components/text/Message"

const useStyles = makeStyles((t) => createStyles({
  main: {
    display: "flex",
    minHeight: "100vh",
    flexDirection: "column",
  },
  scroll: {
    position: "fixed",
    bottom: t.spacing(2),
    right: t.spacing(2),
  },
  toolbar: {
    ...t.mixins.toolbar,
    background: theme.custom.fancyBackground,
  },
  content: {
    flex: 1,
  },
  card: {
    marginTop: "20vh",
    marginBottom: theme.spacing(3),
  },
  form: {
    marginTop: theme.spacing(2),
  },
  link: {
    color: theme.palette.text.secondary,
  },
  container: {},
}))

function ScrollTop(props) {
  const { children, window } = props
  const classes = useStyles()
  // Note that you normally won't need to set the window ref as useScrollTrigger
  // will default to window.
  // This is only being set here because the demo is in an iframe.
  const trigger = useScrollTrigger({
    target: window ? window() : undefined,
    disableHysteresis: true,
    threshold: 100,
  })

  const handleClick = event => {
    scrollTo("#top-anchor")
  }


  return (
    <Zoom in={trigger}>
      <div onClick={handleClick} role="presentation" className={classes.scroll}>
        {children}
      </div>
    </Zoom>
  )
}

ScrollTop.propTypes = {
  children: PropTypes.element.isRequired,
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
}

const IosPage = () => {
  const classes = useStyles()
  const [email, setEmail] = useState("")
  const [emailError, setEmailError] = useState(false)
  const [isLoading, setLoading] = useState(false)
  const [responseMessage, setResponseMessage] = useState({})

  const handleChange = (event) => {
    setEmail(event.target.value)
  }
  const handleClick = () => {
    if (validateEmail(email)) {
      setEmailError(false)
      subscribe(email)
    } else {
      setEmailError(true)
    }
  }

  const subscribe = (email) => {
    setLoading(true)
    axios.post(URLS.API + "newsletter/subscriber/subscribe", {
      list: "ios",
      email: email,
    }, {
      // withCredentials: true
    })
      .then(function(response) {
        if (response.hasOwnProperty("data")) {
          setResponseMessage(response.data)
        }
      })
      .catch(error => {
      })
      .finally(() => {
        setLoading(false)
      })
  }
  return (
    <React.Fragment>
      <Meta title={"Get notified for the iOS version"} />
      <NoSsr>
        <ThemeProvider theme={theme}>
          <CssBaseline/>
          <main className={classes.main}>
            <Header title="Memo Reminders"/>
            <section id={"content"} className={classes.content}>
              <div className={classes.toolbar}/>
              <Container maxWidth={"md"} className={classes.container}>
                <Card className={classes.card}>
                  <CardContent>
                    <Typography align={"center"} variant={"h6"}>Get notified for the iOS version</Typography>
                    <Typography align={"center"} variant={"body1"}>Enter your email below</Typography>
                    <Container maxWidth={"sm"} className={classes.form}>
                      <Grid container spacing={2} justify={"center"} alignItems={"center"}>
                        <Grid item xs={12} sm={8}>
                          <TextField error={emailError}
                                     onChange={handleChange}
                                     onKeyPress={event => {
                                       if (event.charCode === 13) handleClick()
                                     }}
                                     value={email} size={"small"}
                                     fullWidth
                                     label="Email"
                                     type={"email"}
                                     variant="outlined"/>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                          <Button
                            disabled={isLoading}
                            startIcon={isLoading ? <CircularProgress size={16} color={"inherit"}/> : <SubscribeIcon/>}
                            onClick={handleClick} fullWidth variant={"contained"}
                            color={"secondary"}>Subscribe</Button>
                        </Grid>
                        {responseMessage &&
                        <Grid item xs={12}>
                          <Message responseMessage={responseMessage}/>
                        </Grid>
                        }
                        <Grid item xs={12}>
                          <Typography variant={"caption"} color={"textSecondary"}>
                            By submitting this form you agree to receive news for the iOS version of the application,
                            have
                            read
                            our <Link className={classes.link} to={"/data-use"}>Privacy Policy</Link> and agree to
                            our <Link
                            className={classes.link} to={"/terms"}>Terms and conditions</Link>.
                          </Typography>
                        </Grid>
                      </Grid>
                    </Container>

                  </CardContent>
                </Card>
              </Container>
            </section>
            <Footer excludeSupport={true}/>
          </main>
          <ScrollTop>
            <Fab color="secondary" size="small" aria-label="scroll back to top">
              <KeyboardArrowUpIcon/>
            </Fab>
          </ScrollTop>
        </ThemeProvider>
      </NoSsr>
    </React.Fragment>
  )
}

export default IosPage

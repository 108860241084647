import React, { Fragment } from "react"
import { makeStyles } from "@material-ui/core/styles"
import { createStyles } from "@material-ui/core"
import Typography from "@material-ui/core/Typography"


const useStyles = makeStyles((theme) => createStyles({
  message: {
    padding: theme.spacing(1)/2,
    borderRadius:8,
    textAlign:'center'
  },
  errorMessage: {
    padding: theme.spacing(1)/2,
    borderRadius:8,
    textAlign:'center',
    background: theme.palette.error.main,
    color: theme.palette.error.contrastText,
  },
  successMessage: {
    padding: theme.spacing(1)/2,
    borderRadius:8,
    textAlign:'center',
    background: theme.palette.success.light,
    color: theme.palette.getContrastText(theme.palette.success.light),
  },
}))

const Message = ({ responseMessage }) => {

  const classes = useStyles()
  // const [messageType, setMessageType] = useState(classes.message)
  let messages = []
  // const [messages, setMessages] = useState([])
  let messageType = classes.message
  if (responseMessage) {
    if (responseMessage.hasOwnProperty("message"))
      messages.push(responseMessage.message)
    if (responseMessage.hasOwnProperty("messages"))
      messages.push(responseMessage.messages)
    if (responseMessage.success)
      messageType = classes.successMessage
    else if (responseMessage.error)
      messageType = classes.errorMessage
  }
  return (
    <Fragment>
      {messages.map((message, key) =>
        <Typography key={key} className={messageType} variant={"body2"}>{message}</Typography>,
      )}
    </Fragment>

  )
}

export default Message
